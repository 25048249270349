<template>
    <BaseLayout>

        <CarouselLoader v-if="loading.content"/>
        <Carousel v-if="!loading.content && banners.length > 0" :paths="banners" />
    
        <section class="ol-checkout-couponcarousel">
            <div class="ol-wrapper ol-wrapper--large">
                <BannerBottomLoader v-if="loading.vouchers"/>


                <ul v-else class="card-vouchers">
                    <li class="card-voucher" v-for="voucher in vouchers" :key="voucher.id">
                        <div class="main">
                            <div class="content-voucher">
                                <h2><img :src="assetIcon('discount-red.png')" width="15"> Potongan Harga</h2>
                                <h1>{{ voucher.voucher_rp }}</h1>
                                
                                <p>Min. transaksi {{ voucher.min_purchase_rp }}</p>
                                <p>Hingga {{ voucher.expired }}</p>
                            </div>
                        </div>
                        <div class="copy-button">
                            <input type="text" readonly :value="voucher.code" />
                            <button @click="copyNumber(voucher.code)" class="copybtn">Salin</button>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        
        <section class="ol-product">
            <div class="ol-wrapper ol-wrapper--large">
                <div  class="ol-product-content add-fix">
                <ProductLoader :page="1" :qty="8" v-if="loading.content"  style="width:100%!important;" />
                    <div  v-else>
                        <div class="flash-content-title">
                            <h2 v-if="content.group">{{content.group.name}}</h2>
                        </div>
                        <ProductList :products="content.products" /> 
                    </div>
                </div>

            </div>
        </section>
        <div  v-if="!loading.content"  class="inspo-lain">
            <div class="inspo-lain-btn">
                <router-link :to="'/products/type='+content.group.slug" target="_blank" class="white dftbtn">
                    <p>
                        <span class="bg"></span>
                        <span class="base"></span>
                        <span class="text" style="font-size: 0.8rem;">TAMPILKAN SEMUA PRODUK </span>
                    </p>
                </router-link>
            </div>
        </div>
        <div class="ol-addcart" id="copy_url" hidden="true">
            <i class=""></i>Kode Voucher Berhasil disalin
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/landing/BaseLayout.vue';
import Carousel from "component-vue-efi/src/components/carousel/SlideCarousel.vue";
import CarouselLoader from "@/components/loading/CarouselLoader";
import ProductList from "@/components/product/ProductList.vue";
import ProductLoader from '@/components/loading/product/Content.vue';
import BannerBottomLoader from "@/components/loading/BannerBottomLoading";
import {mapActions,mapGetters} from "vuex";

export default {
    name: 'Promotion',
    components:{
        BaseLayout,
        Carousel,
        CarouselLoader,
        ProductList,
        ProductLoader,
        BannerBottomLoader
    },
    
    computed: {
        ...mapGetters("promotion",["vouchers","banners","content"]),

    },
    data() {
        return {
            loading:{
                content: true,
                vouchers: true,
            }
        };
    },
    methods:{
        ...mapActions("promotion",["fetchVouchers","fetchContent"]),
        slicksVoucher(){
            $(".ol-checkout-couponcarousel-list").slick({
                slidesToScroll: 1,
                slidesToShow: 3,
                dots: !1,
                autoplay: !0,
                autoplaySpeed: 5e3,
                arrows: false,
                pauseOnFocus: !1,
                infinite: !0,
                speed: 500,
                fade: !1,
                responsive: [{
                    breakpoint: 891,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            })
        },
        async init(){
            await this.fetchContent(this.$route.params.slug);
            this.loading.content = false;

            // this.analytics('ViewContent',{"content_name": this.content.group.name});

            await this.fetchVouchers();
            this.loading.vouchers = false;
        },
        copyNumber(value) {
            navigator.clipboard.writeText(value).then(function () {
                $('#copy_url').prop('hidden',false);
                setTimeout(() => { $('#copy_url').prop('hidden',true); }, 1500);
            }, function () {
                // alert('Failure to copy. Check permissions for clipboard')
            });

        },
    },

    async mounted() {
        await this.init();
        this.slicksVoucher();

    },
    watch:{
        async $route(to, from) {          
            await this.init();
            this.slicksVoucher();

        },
    }

    
};
</script>

<style lang="css" scoped>
    @import '../../assets/css/coupon.css';
    @import '../../assets/css/88.css';


</style>